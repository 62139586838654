.youtubeContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin-bottom: 50px;
}

.youtubeContainer iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}